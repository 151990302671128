export const ParInput = ({
  inputClass = "",
  type,
  label,
  labelClass = "",
  customClass = "",
  value,
  max,
  min,
  onChange,
  onBlur,
  disabled,
  placeholder,
  onFocus,
  readOnly,
  ref,
}) => {
  return (
    <div className={customClass}>
      <label className={labelClass} htmlFor={label}>
        {label}
      </label>
      <input
        className={inputClass}
        id={label}
        type={type}
        value={value}
        max={max}
        min={min}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        onFocus={onFocus}
        readOnly={readOnly}
        ref={ref}
      />
    </div>
  );
};

export const ParSelect = ({
  label,
  labelClass = "",
  displayKeys,
  idKey,
  options,
  customClass = "",
  selectClass = "",
  divider = "",
  value,
  onChange,
}) => {
  const renderOptions = () => {
    return options.map((option) => {
      return (
        <option value={option[idKey]} key={option[idKey]}>
          {displayKeys.map((key, i) => {
            return `
            ${option[key] ? option[key] : ""}
            ${
              option[key] && divider && displayKeys.length - 1 !== i
                ? divider
                : " "
            }
            `;
          })}
        </option>
      );
    });
  };

  return (
    <div className={customClass}>
      <label className={labelClass} htmlFor={label}>
        {label}
      </label>
      <select
        className={selectClass}
        value={value}
        onChange={onChange}
        id={label}
      >
        <option value="">Select An Option:</option>
        {renderOptions()}
      </select>
    </div>
  );
};

export const ParTextArea = ({
  customClass = "",
  labelName,
  labelClass = "",
  textareaClass = "",
  rows,
  cols,
  placeholder,
  value,
  onChange,
  disabled,
  readOnly,
}) => {
  return (
    <div className={customClass}>
      <label className={labelClass}>{labelName}</label>
      <textarea
        className={textareaClass}
        id={labelName}
        rows={rows}
        cols={cols}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
      />
    </div>
  );
};

export const ParCheckbox = ({ checked, disabled, onChange, labelName }) => {
  return (
    <div className="checkbox-wrapper">
      <label className="checkbox-label" htmlFor={labelName}>
        {labelName}
      </label>
      <input
        className="checkbox"
        checked={checked}
        id={labelName}
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};

export const ParTable = ({ tableClass = "", tableHeaders, tableRows }) => {
  return (
    <table className={tableClass}>
      <thead>
        <tr>
          {tableHeaders.map((header, index) => {
            if (!header) {
              return null;
            } else {
              return (
                <th key={index} className={header.class}>
                  {header.name}
                </th>
              );
            }
          })}
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};
