import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import ParNotificationItem from "./ParNotificationItem";

const groupByJob = (pars) => {
  const grouped = pars.reduce((groups, par) => {
    const jobNumber = par.jobNumber;
    if (!groups[jobNumber]) {
      groups[jobNumber] = [];
    }
    groups[jobNumber].push(par);
    return groups;
  }, {});

  const sortedEntries = Object.entries(grouped).sort((a, b) => {
    const jobA = parseInt(a[0], 10);
    const jobB = parseInt(b[0], 10);
    return jobB - jobA;
  });

  return Object.fromEntries(sortedEntries);
};

const JobGroupList = ({
  pars,
  handleSelectPendingPar = null,
  showDelete = true,
  linkText = "Approve?",
  searchParams = null,
  title,
  gradientClass = "blue-gradient",
  checkIconColor = "#0066b2",
  icon = "fa fa-check-circle",
}) => {
  const getPathname = (par, searchParams) => {
    if (searchParams) {
      return `/select-print/${par.dcrNumber}`;
    }
    return `/approvals/${par.dcrNumber}`;
  };

  return (
    <div className="job-group-container">
      <h1 className="job-group-header">{title}</h1>
      <div className="filter-search-wrapper">
        {pars.length === 0 ? (
          <h2>No PARs</h2>
        ) : (
          Object.entries(groupByJob(pars)).map(([jobNumber, groupPars]) => (
            <div key={jobNumber} className="job-group">
              <div className="job-group-title">
                <div className="job-number-wrapper">
                  <FontAwesomeIcon icon={faFolder} className="folder-icon" />
                  <span className="job-number">
                    {jobNumber} ({groupPars.length})
                  </span>
                </div>
              </div>

              <div className="job-group-items">
                {groupPars
                  .map((par) => (
                    <ParNotificationItem
                      key={par.id || par.dcrNumber}
                      formData={par}
                      handleSelectPendingPar={handleSelectPendingPar}
                      showDelete={showDelete}
                      linkText={linkText}
                      gradientClass={gradientClass}
                      checkIconColor={checkIconColor}
                      icon={icon}
                      to={{
                        pathname: getPathname(par, searchParams),
                        state: {
                          fromApprovals: true,
                          ...(searchParams && { searchParams }),
                        },
                      }}
                    />
                  ))
                  .sort(
                    (a, b) =>
                      a.props.formData.created_at - b.props.formData.created_at
                  )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default JobGroupList;
