import { useState } from "react";
import { useHistory } from "react-router-dom";

import asyncAPICall from "../../../util/apiWrapper";
import useAbortEffect from "../../../components/hooks/useAbortEffect";

import { errorToast, successfulToast } from "../../../util/toastNotifications";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { checkSameDayPar } from "../../../components/helpers/checkSameDayPar";
import { baseParFormData } from "../../../components/reducer/store/baseParFormData";

export default function useApprovalFunctions() {
  const { parFormDataState, parDispatch } = useCurrentPar();
  const history = useHistory();
  const pathRef = window.location.href;
  const pathRefArray = pathRef.split("/", 3);
  const dynamicPath = pathRefArray.join("/");
  const approvalLink = `${dynamicPath}/approvals/`;
  const printLink = `${dynamicPath}/select-print/`;

  const [pmEmail, setPmEmail] = useState("");

  useAbortEffect(
    (signal) => {
      if (parFormDataState.jobNumber) {
        asyncAPICall(
          `api:KE7MCNYf/vprojectswithid_by_Project?project=${parFormDataState.jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data && data.length > 0) {
              setPmEmail(data[0].PM_Email);
            }
          },
          (err) => {
            if (!signal.aborted && parFormDataState.foreman !== 1111) {
              console.log("Getting PM Email Error", err);
            }
          },
          signal
        );
      }
    },
    [parFormDataState.jobNumber]
  );

  const checkIfLate = (parData) => {
    return new Date().getTime() > parData.PREndDateTimestamp;
  };

  const getStatusToastMessage = (status) => {
    if (status === "Late") {
      return `
        The approval period for this PAR has closed.
        <br/><br/>
        Due to BHI's policy, please complete a payroll correction form.
        <br/><br/>
        The form will open in 5 seconds.
      `;
    }

    return `PAR ${status === "Reject" ? "rejected" : "approved"} successfully`;
  };

  const submitFullPar = async (
    parData,
    approverEmail,
    setButton,
    pfs,
    foreman,
    sendEmail
  ) => {
    try {
      setButton(true);
      let sameDayValue = false;

      if (!parData.dcrNumber) {
        sameDayValue = await checkSameDayPar(
          parData.jobNumber,
          parData.date,
          parData.foreman
        );
      }

      if (sameDayValue) {
        errorToast(
          `A PAR has already been created for ${parData.jobNumber} on ${parData.date}. SAMEDAY`
        );
        history.push("/par");
        return;
      }

      const url = parData.id
        ? `api:W2oHkD04/partblmain/resubmissionById?id=${parData.id}`
        : "api:W2oHkD04/partblmain";

      const isLate = checkIfLate(parData);

      const body = {
        ...parData,
        dcrStatus: isLate ? "Late" : "Pending",
        approvedAfterPayPeriodClosed: isLate,
      };

      if (body.statusComments) {
        body.statusComments = body.statusComments.map((comment) => ({
          ...comment,
          status: body.dcrStatus,
        }));
      }

      const response = await asyncAPICall(
        url,
        "POST",
        body,
        null,
        async (data) => {
          successfulToast("Par Submitted Successfully");

          if (approverEmail) {
            await sendApprovalEmail(
              approverEmail,
              body,
              foreman,
              data.dcrNumber
            );
          }

          if (pmEmail && body.idb?.length > 0) {
            await sendIssueEmail(pmEmail, body, foreman);
          }

          if (sendEmail) {
            await sendTimeMaterialsEmail(
              sendEmail,
              parData,
              pfs,
              foreman,
              data.dcrNumber
            );
          }

          localStorage.clear();
          parDispatch({
            type: "setParForm",
            form: baseParFormData,
          });

          history.push("/overview");
        },
        (err) => {
          console.error("sending PAR error: ", err);
          errorToast("Failed To Submit PAR");
          setButton(false);
        }
      );

      return response;
    } catch (error) {
      console.error("Error in submitFullPar:", error);
      errorToast("Failed To Submit PAR");
      setButton(false);
    }
  };

  const parApprovalStatus = (status, parData, setButton) => {
    if (!parData || !parData.id) {
      errorToast("Invalid PAR data");
      setButton(false);
      return;
    }

    setButton(true);

    const isLate = checkIfLate(parData);

    const body = {
      ...parData,
      dcrStatus: status === "Approved" && isLate ? "Late" : status,
      approvedAfterPayPeriodClosed: isLate,
    };

    if (body.statusComments) {
      if (status === "Reject") {
        body.statusComments = body.statusComments.map((comment, idx) => ({
          ...comment,
          status:
            idx === body.statusComments.length - 1 ? status : comment.status,
        }));
      } else {
        body.statusComments = body.statusComments.map((comment) => ({
          ...comment,
          status: body.dcrStatus,
        }));
      }
    }

    asyncAPICall(
      `api:W2oHkD04/partblmain/resubmissionById?id=${parData.id}`,
      "POST",
      body,
      (res) => {
        if (res.ok) {
          const message = getStatusToastMessage(body.dcrStatus);
          if (body.dcrStatus === "Late") {
            errorToast(message, true);
            setTimeout(() => {
              window.open(
                "https://form.asana.com/?k=vusqZD1ncVz8iJd2bKMAew&d=511103130615243",
                "_blank",
                "noopener,noreferrer"
              );
            }, 5000);
          } else {
            successfulToast(message);
          }

          history.push("/approvals");
          localStorage.clear();
        } else {
          errorToast(
            `Unable to ${status.toLowerCase()} PAR for ${
              parData.jobNumber
            } on ${parData.date}`
          );
          setButton(false);
        }
      },
      null,
      (err) => {
        console.error("Sending Updated Status Error", err);
        errorToast(`Error ${status.toLowerCase()}ing PAR`);
        setButton(false);
      },
      null,
      true
    );
  };

  const sendApprovalEmail = (approverEmail, parData, foreman, dcrNumber) => {
    if (parData.foreman !== 1111) {
      asyncAPICall(
        `api:5e9BgwVw/sendgrid/dynamicEmail`,
        "POST",
        {
          to_email: approverEmail,
          template_id: "d-6e389b8ff84145748ca6ff0b7ad58864",
          data: {
            date: `${parData.date}`,
            foreman: `${foreman}`,
            jobNumber: `${parData.jobNumber}`,
            link: `${approvalLink}`,
          },
        },
        null,
        null,
        (err) => {
          console.log("Sending approval email error: ", err);
        },
        null,
        true
      );
    }
  };

  const sendIssueEmail = (pmEmail, parData, foreman) => {
    if (parData.idb[0]?.issueNumber.length > 1) {
      asyncAPICall(
        `api:5e9BgwVw/sendgrid/dynamicEmail`,
        "POST",
        {
          to_email: `${pmEmail}`,
          template_id: "d-2db62e9a0cb94c488959a15ec9f319a4",
          data: {
            date: `${parData.date}`,
            foreman: `${foreman}`,
            jobNumber: `${parData.jobNumber}`,
          },
        },
        null,
        null,
        (err) => {
          console.log("Sending issues email error: ", err);
        },
        null,
        true
      );
    }
  };

  const sendTimeMaterialsEmail = (
    sendEmail,
    parData,
    pfs,
    foreman,
    dcrNumber
  ) => {
    asyncAPICall(
      `api:5e9BgwVw/sendgrid/dynamicEmail`,
      "POST",
      {
        to_email: `${sendEmail}`,
        template_id: "d-1a19486813524880829e09c2a962270f",
        data: {
          jobNumber: `${parData.jobNumber}`,
          date: `${parData.date}`,
          approver: `${pfs}`,
          submitter: `${foreman}`,
          link: `${printLink}${dcrNumber}`,
        },
      },
      null,
      null,
      (err) => {
        console.error("Sending Coordinator Email", err);
      },
      null,
      true
    );
  };

  return {
    submitFullPar,
    parApprovalStatus,
    sendApprovalEmail,
    sendIssueEmail,
    sendTimeMaterialsEmail,
  };
}
