import { useState, useEffect, useCallback } from "react";

import ConfirmationModal from "../../../components/modals/confirmation-modal/ConfirmationModal";
import SubcontractorsSection from "./SubcontractorsSection";
import TimeMaterialsSection from "./TimeMaterialsSection";
import VerbalCounselSection from "./VerbalCounselSection";
import IncidentsSection from "./IncidentsSection";
import MaterialsSection from "./MaterialsSection";
import InjuriesSection from "./InjuriesSection";
import IssuesSection from "./IssuesSection";
import { useCurrentPar } from "../../../components/contexts/CurrentParContext";
import { ParCheckbox } from "../../../components/helpers/ParFormComponents";
import { useModalContext } from "../../../components/contexts/ModalContext";

const SiteActivityCheckboxSection = (props) => {
  const { setBottomPhaseAdd } = props;
  const [checkboxType, setCheckboxType] = useState("");

  const { parFormDataState, parDispatch } = useCurrentPar();
  const { handleCloseModal, handleOpenModal, isModalOpen, modalDescription } =
    useModalContext();

  const { issues, idb, subcontractor, material, hrvc, checkboxes } =
    parFormDataState;

  const basicFieldUpdate = useCallback(
    (value, field, type) => {
      parDispatch({
        type,
        payload: {
          field,
          value,
        },
      });
    },
    [parDispatch]
  );

  const confirmationModalInfo = {
    addAdditionalIssue: {
      modalQuestion: "Would you like to add another Issues record?",
      onConfirm: () => handleAddAdditionalIssue(),
    },
    verifyNoIssues: {
      modalQuestion: "Are you sure there were no Issues with this Par?",
      onConfirm: () => handleConfirmNoIssues(),
    },
  };

  const getDefaultEntry = useCallback(
    (type) => {
      const defaults = {
        idb: {
          issueNumber: "0",
          raisedBy: "",
          issueType: "No Issues Entered",
          dateRaised: "",
          description: "",
        },
        subcontractor: {
          id: 0,
          name: "none",
          vendor: "",
          description: "None of our subcontractors were observed this period.",
          dailyHours: "0",
          employeeCount: "0",
          type: "none",
        },
        material: {
          id: 0,
          description: "None of our materials were observed this period.",
          phaseCode: "",
          phaseDesc: "",
          units: "0.00",
          uom: "",
          cost: "0.00",
          total: "0.00",
        },
        verbalCounsel: {
          counselNumber: 0,
          submissionDate: parFormDataState.date,
          employeeName: "",
          employeeId: null,
          submitterName: "",
          submitterId: parFormDataState.foreman,
          interaction: "",
          counsel: "No Counsels Entered.",
          response: "",
        },
      };
      return defaults[type];
    },
    [parFormDataState.date, parFormDataState.foreman]
  );

  const handleGenericCheckbox = useCallback(
    (type, currentValue) => {
      if (["incidents", "injuries"].includes(type)) {
        const titledType = type.charAt(0).toUpperCase() + type.slice(1);
        const message = currentValue
          ? "Nothing to Report."
          : `One or more ${titledType} were reported in accordance with SOP's.`;

        basicFieldUpdate(message, type, "updateInputField");
      }

      parDispatch({
        type: "updateCheckbox",
        payload: {
          field: type,
          value: !currentValue,
        },
      });
    },
    [basicFieldUpdate, parDispatch]
  );

  const handleArrayTypeCheckbox = useCallback(
    (type, currentValue, currentArray) => {
      if (type === "issues") {
        if (currentValue) {
          const emptyIssue = {
            issueNumber: "0",
            raisedBy: "",
            issueType: "No Issues Entered",
            dateRaised: "",
            description: "",
          };
          basicFieldUpdate([emptyIssue], "idb", "updateInputField");
          basicFieldUpdate("Nothing to Report.", "issues", "updateInputField");
        } else {
          basicFieldUpdate([], "idb", "updateInputField");
          basicFieldUpdate("", "issues", "updateInputField");
        }
      } else {
        if (currentValue) {
          const defaultEntry = getDefaultEntry(type);
          basicFieldUpdate([defaultEntry], type, "updateInputField");
        } else {
          basicFieldUpdate([], type, "updateInputField");
        }
      }

      parDispatch({
        type: "updateCheckbox",
        payload: {
          field: type,
          value: !currentValue,
        },
      });
    },
    [basicFieldUpdate, parDispatch, getDefaultEntry]
  );

  const checkboxItems = [
    {
      checked: checkboxes.issues,
      labelName: "Issues?",
      onChange: () => {
        if (checkboxes.issues) {
          setCheckboxType("issues");
          handleOpenModal("verifyNoIssues");
        } else {
          handleArrayTypeCheckbox("issues", checkboxes.issues, idb);
        }
      },
      disabled: idb?.length > 0 && checkboxes.issues,
    },
    ...(Object.keys(checkboxes).includes("verbalCounsel")
      ? [
          {
            checked: checkboxes.verbalCounsel,
            labelName: "Verbal Counsel?",
            onChange: handleVerbalCounselCheckbox,
            disabled: hrvc?.length > 0,
          },
        ]
      : []),
    {
      checked: checkboxes.incidents,
      labelName: "Incidents?",
      onChange: () => handleGenericCheckbox("incidents", checkboxes.incidents),
      disabled: false,
    },
    {
      checked: checkboxes.injuries,
      labelName: "Injuries?",
      onChange: () => handleGenericCheckbox("injuries", checkboxes.injuries),
      disabled: false,
    },
    {
      checked: checkboxes.subcontractor,
      labelName: "Subcontractors?",
      onChange: handleSubcontractorCheckbox,
      disabled: subcontractor[0]?.id !== 0 && subcontractor.length > 0,
    },
    {
      checked: checkboxes.timeMaterials,
      labelName: "Time & Material?",
      onChange: handleTimeMaterialCheckbox,
      disabled: isTimeMaterialCheckboxDisabled(),
    },
    {
      checked: checkboxes.material,
      labelName: "Materials?",
      onChange: handleMaterialCheckbox,
      disabled: material?.length > 0 && material[0]?.id !== 0,
    },
  ];

  const handleConfirmNoIssues = () => {
    if (checkboxType === "issues") {
      const emptyIssue = {
        issueNumber: "0",
        raisedBy: "",
        issueType: "No Issues Entered",
        dateRaised: "",
        description: "",
      };

      basicFieldUpdate([emptyIssue], "idb", "updateInputField");
      basicFieldUpdate("Nothing to Report.", "issues", "updateInputField");

      parDispatch({
        type: "updateCheckbox",
        payload: {
          field: "issues",
          value: false,
        },
      });
    }

    handleCloseModal("verifyNoIssues");
  };

  const handleAddAdditionalIssue = () => {
    handleCloseModal("addAdditionalIssue");
    handleOpenModal("addIssue");
  };

  function handleSubcontractorCheckbox() {
    if (checkboxes.subcontractor) {
      const emptySubcontractor = {
        id: 0,
        name: "none",
        vendor: "",
        description: "None of our subcontractors were observed this period.",
        dailyHours: "0",
        employeeCount: "0",
        type: "none",
      };

      basicFieldUpdate(
        [emptySubcontractor],
        "subcontractor",
        "updateInputField"
      );
    } else {
      basicFieldUpdate([], "subcontractor", "updateInputField");
    }

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "subcontractor",
        value: !checkboxes.subcontractor,
      },
    });
  }

  function isTimeMaterialCheckboxDisabled() {
    return !(
      parFormDataState.matCustomerCode === "" &&
      parFormDataState.matOsr === "" &&
      parFormDataState.matJobsite === ""
    );
  }

  function handleTimeMaterialCheckbox() {
    if (checkboxes.timeMaterials) {
      basicFieldUpdate("", "matCustomer", "updateInputField");
      basicFieldUpdate("", "matCustomerCode", "updateInputField");
      basicFieldUpdate("", "matOsr", "updateInputField");
      basicFieldUpdate("", "matJobsite", "updateInputField");
    }

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "timeMaterials",
        value: !checkboxes.timeMaterials,
      },
    });
  }

  function handleMaterialCheckbox() {
    if (checkboxes.material) {
      const emptyMaterial = {
        id: 0,
        description: "None of our materials were observed this period.",
        phaseCode: "",
        phaseDesc: "",
        units: "0.00",
        uom: "",
        cost: "0.00",
        total: "0.00",
      };

      basicFieldUpdate([emptyMaterial], "material", "updateInputField");
    } else {
      basicFieldUpdate([], "material", "updateInputField");
    }

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "material",
        value: !checkboxes.material,
      },
    });
  }

  function handleVerbalCounselCheckbox() {
    if (checkboxes.verbalCounsel) {
      const emptyVerbalCounsel = {
        counselNumber: 0,
        submissionDate: parFormDataState.date,
        employeeName: "",
        employeeId: null,
        submitterName: "",
        submitterId: parFormDataState.foreman,
        interaction: "",
        counsel: "No Counsels Entered.",
        response: "",
      };

      basicFieldUpdate(
        [emptyVerbalCounsel],
        "verbalCounsel",
        "updateInputField"
      );
    }

    parDispatch({
      type: "updateCheckbox",
      payload: {
        field: "verbalCounsel",
        value: !checkboxes.verbalCounsel,
      },
    });
  }

  useEffect(() => {
    if (!idb) {
      if (issues !== "Nothing to Report." && issues.length > 0) {
        parDispatch({
          type: "addDeprecatedIdb",
          payload: issues,
        });
      }
    } else if (
      idb.length === 0 &&
      issues !== "Nothing to Report." &&
      issues.length > 0
    ) {
      parDispatch({
        type: "addDeprecatedIdb",
        payload: issues,
      });
    }
  }, [idb, issues, parDispatch]);

  useEffect(() => {
    if (!Object.keys(checkboxes).includes("verbalCounsel")) {
      parDispatch({
        type: "updateCheckbox",
        payload: {
          field: "verbalCounsel",
          value: false,
        },
      });
    }
  }, [checkboxes, parDispatch]);

  return (
    <>
      <div className="par-checkbox-container">
        <div className="checkbox-item">
          {checkboxItems.map((checkbox) => {
            return (
              <ParCheckbox
                key={checkbox.labelName}
                checked={checkbox.checked}
                labelName={checkbox.labelName}
                onChange={checkbox.onChange}
                disabled={checkbox.disabled}
              />
            );
          })}
        </div>
      </div>

      <div className="problems-holder">
        <IssuesSection />

        <VerbalCounselSection />

        <IncidentsSection />

        <InjuriesSection />

        <SubcontractorsSection />

        <TimeMaterialsSection />

        <MaterialsSection setBottomPhaseAdd={setBottomPhaseAdd} />
      </div>

      {Object.keys(confirmationModalInfo).includes(modalDescription) && (
        <ConfirmationModal
          isModalOpen={isModalOpen[modalDescription]}
          onRequestClose={() => handleCloseModal(modalDescription)}
          onConfirm={confirmationModalInfo[modalDescription].onConfirm}
          modalQuestion={confirmationModalInfo[modalDescription].modalQuestion}
        />
      )}
    </>
  );
};

export default SiteActivityCheckboxSection;
